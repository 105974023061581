<template>
  <v-card
    class="pa-2 mx-2 border-theme bg-white"
    :width="fixedTrustedReview ? 'auto' : !mdAndUp ? slideGroupContainerWidth : '310'"
    :max-width="mdAndUp ? '100%' : 'unset'"
    height="180"
    variant="outlined"
    rounded="lg"
    @click="clickEvent">
    <v-row>
      <v-col :class="{ 'd-flex': fixedTrustedReview, 'justify-center': fixedTrustedReview }">
        <v-rating v-model="numberOfStartRo" density="compact" active-color="primary" disabled />
      </v-col>

      <v-col v-if="verifiedCheckmark" class="d-flex justify-end align-center">
        <v-icon color="secondary">mdi-check-decagram</v-icon>
      </v-col>
    </v-row>

    <v-card-item class="pb-0">
      <v-card-subtitle v-if="reviewDateString">
        {{ new Date(reviewDateString).toLocaleDateString('de-DE') }}
      </v-card-subtitle>

      <v-card-title v-if="fixedTrustedReview" align="center">
        {{ numberOfStars.toString() }}
      </v-card-title>
    </v-card-item>

    <v-card-text v-if="!fixedTrustedReview">
      {{ reviewText.length > 100 ? reviewText.slice(0, 100) + '...' : reviewText }}
    </v-card-text>

    <v-card-item v-if="fixedTrustedReview" align="center" class="py-0">
      <v-card-subtitle class="text-h6 font-weight-bold opacity-100 text-black">
        {{ reviewText }}
      </v-card-subtitle>
    </v-card-item>

    <v-img
      v-if="fixedTrustedReview"
      :max-height="smAndUp ? '50px' : '80px'"
      :alt="t('trusted_site')"
      :src="`https://epd.directus.app/assets/${trustedLogoUrl}?format=webp`"
      :srcset="`https://epd.directus.app/assets/${trustedLogoUrl}?format=webp 1x, https://epd.directus.app/assets/${trustedLogoUrl}?format=webp 2x`" />
  </v-card>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { smAndUp, mdAndUp } = useDisplay()
const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    numberOfStars: number
    reviewDateString?: string
    reviewText: string
    verifiedCheckmark?: boolean
    fixedTrustedReview?: boolean
    trustedLogoUrl?: string
    clickEvent?: () => void
  }>(),
  {
    fixedTrustedReview: false,
    reviewDateString: undefined,
    clickEvent: undefined,
    trustedLogoUrl: undefined,
    verifiedCheckmark: true,
  },
)

const numberOfStartRo = ref(Math.round(props.numberOfStars))

const slideGroupContainerWidth = ref(0)

function calculateSlideGroupMaxWidth() {
  const body = document.querySelector('body')

  // This is how the width is calculated:
  // take the body width (=== device/viewport-width)
  // 2 * 12 = body margins
  // 2 * 52 = width or arrows in slide container
  // 2 * 8 = margin of slide container

  slideGroupContainerWidth.value =
    (body?.getBoundingClientRect().width ?? 300) - 2 * 12 - 2 * 52 - 2 * 8
  if (
    (body?.getBoundingClientRect()?.width ?? 0) <= 959 &&
    (body?.getBoundingClientRect()?.width ?? 0) >= 620
  )
    slideGroupContainerWidth.value = slideGroupContainerWidth.value / 2 - 10
}

onMounted(() => {
  calculateSlideGroupMaxWidth()

  window.addEventListener('resize', calculateSlideGroupMaxWidth)
})

onUnmounted(() => {
  document.removeEventListener('resize', calculateSlideGroupMaxWidth)
})
</script>

<style scoped lang="scss">
.custom-rating {
  :deep(button) {
    height: 40px;
  }
}
</style>
